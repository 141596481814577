import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { registerUser, userLogin } from "../../apiCalls";
import { toast } from 'react-toastify';
import { Stepper, Step, StepLabel } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { createCustomer } from "../../apiCalls";
import EditIcon from '@mui/icons-material/Edit';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Auth({ openAuth, handleAuthClose }) {
  const [isLogin, setIsLogin] = useState(true);
  const [activeStep, setActiveStep] = useState(0);  // Stepper state

  // Login form state
  const [loginFormData, setLoginFormData] = useState({
    email: '',
    password: '',
  });
  const [loginErrors, setLoginErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const [signupFormData, setSignupFormData] = useState({
    organizationName: '',
    name: '',
    title: '',
    phone: '',
    email: '',
    country: 'India',
    streetAddress: '',
    city: '',
    state: '',
    zipCode: '',
    gst: '',
    password: '',
    confirmPassword: '',
  });

  const [signupErrors, setSignupErrors] = useState({});
  const [otpSent, setOtpSent] = useState({ email: false, phone: false });
  const [otpVal, setOtpVal] = useState({ email: '467853', phone: '874563' });
  const [otp, setOtp] = useState({ emailOtp: '', phoneOtp: '' });

  const [isVerified, setIsVerified] = useState({ email: false, phone: false });
  const steps = ['Enter Details', 'Verify & Create'];

  const toggleAuthMode = () => {
    setIsLogin((prev) => !prev);
    setLoginErrors({});
    setSignupErrors({});
    setOtpSent({ email: false, phone: false });
    setOtp({ emailOtp: '', phoneOtp: '' });
    setLoginFormData({ email: '', password: '' });
    setSignupFormData({ name: '', email: '', phone: '', password: '', confirmPassword: '' });
    setActiveStep(0);  // Reset stepper when switching modes
  };

  
  // Handle input change for login form
  const handleLoginChange = (e) => {
    const { name, value } = e.target;
    setLoginFormData((prev) => ({ ...prev, [name]: value }));
  };

  // Handle input change for signup form
  const handleSignupChange = (e) => {
    const { name, value } = e.target;
    setSignupFormData((prev) => ({ ...prev, [name]: value }));
  };

  // Handle OTP change
  const handleOtpChange = (e) => {
    const { name, value } = e.target;
    setOtp((prev) => ({ ...prev, [name]: value }));
  };

  const handleNext = () => {
    const errors = {};
  
    // Validate required fields
    Object.entries(signupFormData).forEach(([key, value]) => {
      if (!value && key !== 'password' && key !== 'confirmPassword' && key !== 'gst') {
        errors[key] = 'This field is required';
      }
    });
  
    // Set errors if any
    if (Object.keys(errors).length > 0) {
      setSignupErrors(errors);
      return;
    }
  
    // If no errors, proceed to the next step
    setSignupErrors({});
    setActiveStep((prevStep) => prevStep + 1);
  };
  

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  // Validate login form
  const validateLoginForm = () => {
    const newErrors = {};
    if (!loginFormData.email) newErrors.email = 'Email is required';
    if (!loginFormData.password) newErrors.password = 'Password is required';
    setLoginErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Validate signup form for step 1
  const validateSignupStep1 = () => {
    const newErrors = {};
    if (!signupFormData.name) newErrors.name = 'Name is required';
    if (!signupFormData.email) newErrors.email = 'Email is required';
    if (!signupFormData.phone) newErrors.phone = 'Phone number is required';
    setSignupErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Validate signup form for step 2
  const validateSignupStep2 = () => {
    const newErrors = {};
    if (!signupFormData.password) newErrors.password = 'Password is required';
    if (signupFormData.password !== signupFormData.confirmPassword)
      newErrors.confirmPassword = 'Passwords do not match';
    setSignupErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Handle login submit
  const handleLoginSubmit = async () => {
    if (validateLoginForm()) {
      setLoading(true);
      try {
        const response = await userLogin(loginFormData);
        if (response.success) {
          toast.success(response.message);
          handleAuthClose();
          const userJSON = JSON.stringify(response.user);
          localStorage.setItem('appbowlUser', userJSON);
        } else {
          toast.error(response.message);
        }
      } catch (error) {
        console.error('Login error:', error);
        toast.error('Login failed');
      }
      setLoading(false);
    }
  };

  // Handle signup submit
  const handleSignupSubmit = async () => {
    if (activeStep === 0 && validateSignupStep1()) {
      handleNext(); // Proceed to the next step if step 1 is valid
    } else if (activeStep === 1 && validateSignupStep2()) {
      setLoading(true);
  
      try {
        // Construct payload for the first API
        const soData = {
          customerOrganization: signupFormData.organizationName,
          customerAddress: {
            street: signupFormData.streetAddress,
            city: signupFormData.city,
            state: signupFormData.state,
            zip: signupFormData.zipCode,
            country: "IN",
          },
          customerName: signupFormData.name,
          customerEmail: signupFormData.email,
          customerTitle: signupFormData.title,
          customerPhone: signupFormData.phone,
          gst: signupFormData.gst,
          languageCode: "EN",
        };
  
        const customerResponse = await createCustomer(soData);
        if (!customerResponse.success || !customerResponse.data?.customer_id) {
          toast.error("Failed to create customer");
          return; 
        }
  
        const customerId = customerResponse.data.customer_id;
  
        const regData = {
          name: signupFormData.name,
          phone: signupFormData.phone,
          email: signupFormData.email,
          password: signupFormData.confirmPassword,
          customerId: customerId, 
        };
  
        // Call the second API
        const userResponse = await registerUser(regData);
        if (userResponse.success) {
          toast.success("Registered Successfully");
          toggleAuthMode();
        } else {
          toast.error("Failed to register user");
        }
      } catch (error) {
        console.error("Signup error:", error);
        toast.error("Signup failed");
      } finally {
        setLoading(false); 
      }
    }
  };
  
  

  // Send OTP for email or phone
  const handleSendOtp = (type) => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setOtpSent((prev) => ({ ...prev, [type]: true }));
    }, 1000);
    
  };

  const handleVerifyOtp = (type) => {
    if(type === 'email'){
      if(otp.emailOtp == otpVal.email){
        toast.success('Email verified successfully!')
        setIsVerified((prev) => ({ ...prev, ['email']: true }));
        setOtpSent((prev) => ({ ...prev, ['email']: false }));
      }
      else{
        toast.error('Email OTP is incorrect!')

      }
    }
    if(type === 'phone'){
      if(otp.phoneOtp == otpVal.phone){
        toast.success('Mobile number verified successfully!')
        setIsVerified((prev) => ({ ...prev, ['phone']: true }));
        setOtpSent((prev) => ({ ...prev, ['phone']: false }));
      }
      else{
        toast.error('Mobile OTP is incorrect!')

      }
    }
  }
  const handlePhoneVerifyClick = () => {
    setOtpSent({phone: !otpSent.phone})
  }

  const handleEmailVerifyClick = () => {
    setOtpSent({email: !otpSent.email})
  }
  return (
    <Dialog open={openAuth} TransitionComponent={Transition} fullScreen keepMounted onClose={handleAuthClose} sx={{zIndex: '1600'}} >
      <DialogContent sx={{ p: 4, borderRadius: 2 , overflow: 'auto', display: "flex", justifyContent: "center", alignItems: "center"}}>
      
      <Box sx={{ position: 'absolute', top : '4vh', right: '2%', zIndex: '1600' }}>
              <Button variant="body2" gutterBottom onClick={handleAuthClose}><CloseIcon /></Button>
            </Box>
        <Grid container >
          {/* Left Side */}
          <Grid
            item xs={12} md={5}
            sx={{
              background: 'linear-gradient(to bottom, #1a237e, #3f51b5)',
              color: 'white',
              display:{xs:'none',sm:'none',md:'flex'},
              alignItems: 'center',
              justifyContent: 'center',
              p: 4,
              backgroundImage: 'url(https://source.unsplash.com/random/?login)',
              backgroundSize: 'cover',
              flexDirection: 'column',
              borderRadius: '8px 0 0 8px',
              position: 'relative',
              overflow: 'hidden'
            }}
          >
            <Box sx={{ backgroundColor: 'rgba(0, 0, 0, 0.6)', p: 3, borderRadius: 3, textAlign: 'center' }}>
              <Typography variant="h4" gutterBottom>{isLogin ? 'Welcome Back!' : 'Join Us!'}</Typography>
              <Typography variant="body2">{isLogin ? 'Login to your account to continue.' : 'Sign up to get started.'}</Typography>
            </Box>
            <Grid container direction="column" alignItems="center">
              <Grid item xs={12}>
                <Typography variant="h5" gutterBottom>
                  {isLogin ? 'Login' : 'Sign Up'}
                </Typography>
                <Button onClick={() => setIsLogin(!isLogin)}>
                  {isLogin ? 'Create an Account' : 'Already have an account? Login'}
                </Button>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={7} sx={{ bgcolor: 'white', p:2, borderRadius: '0 8px 8px 0' }}>
            <Box sx={{ position: 'relative' }}>
              {/* <Typography variant="h6" textAlign='center' fontWeight="bold" sx={{ mb: 2 }}>{isLogin ? 'Login' : 'Sign Up'}</Typography> */}

              {!isLogin && (
                <>
                  {/* <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map((label, index) => (
                      <Step key={index}>
                        <StepLabel>{label}</StepLabel>
                      </Step>
                    ))}
                  </Stepper> */}

                  {/* Step 1 */}
                  {activeStep === 0 && (
                    <>
                     <Grid container justifyContent="space-between">
                        <Grid item xs={12} sm={12} md={12}>
                          <TextField
                            fullWidth
                            size="small"
                            label="Organization Name"
                            name="organizationName"
                            variant="outlined"
                            margin="normal"
                            value={signupFormData.organizationName}
                            onChange={handleSignupChange}
                            error={!!signupErrors.organizationName}
                            required
                          />
                        </Grid>

                     <Grid item xs={12} sm={12} md={5.9}>
                          <TextField
                            fullWidth
                            size="small"
                            label="Name"
                            name="name"
                            variant="outlined"
                            margin="normal"
                            value={signupFormData.name}
                            onChange={handleSignupChange}
                            error={!!signupErrors.name}
                            required
                          />
                        </Grid>
                        

                        <Grid item xs={12} sm={12} md={5.9}>
                          <TextField
                            fullWidth
                            size="small"
                            label="Title"
                            name="title"
                            variant="outlined"
                            margin="normal"
                            value={signupFormData.title}
                            onChange={handleSignupChange}
                            error={!!signupErrors.title}
                            required
                          />
                        </Grid>

                        <Grid item xs={12} sm={12} md={5.9}>
                        {!otpSent.phone ?
                         ( <TextField
                            fullWidth
                            size="small"
                            label="Phone"
                            name="phone"
                            variant="outlined"
                            margin="normal"
                            value={signupFormData.phone}
                            onChange={handleSignupChange}
                            error={!!signupErrors.phone}
                            required
                            InputProps={{
                              endAdornment: (
                                <Typography
                                  variant="body2"
                                  style={{ cursor: "pointer", textDecoration: 'underline',paddingLeft: '4%' }}
                                  onClick={handlePhoneVerifyClick} 
                                >
                                  Verify
                                </Typography>
                              ),
                            }}
                          />)
                          :
                          (<TextField
                            fullWidth
                            size="small"
                            label="OTP"
                            name="mobileOtp"
                            variant="outlined"
                            margin="normal"
                            value={signupFormData.mobileOtp}
                            onChange={handleSignupChange}
                            error={!!signupErrors.mobileOtp}
                            InputProps={{
                            endAdornment: (
                              <Grid
                                container
                                justifyContent="flex-end"
                                alignItems="center"
                                wrap="nowrap"
                                style={{ width: 'auto' }}
                              >
                                <Typography
                                  variant="body2"
                                  color="primary"
                                  style={{ cursor: "pointer",  textDecoration: 'underline', paddingLeft: '4%' }}
                                  // onClick={}
                                >
                                  Submit
                                </Typography>
                                <Typography
                                  variant="body2"
                                  style={{ padding: '0 6px' }}
                                >
                                  /
                                </Typography>
                                <EditIcon
                                  size="small"
                                  style={{ cursor: "pointer",fontSize: '1rem', }}
                                  onClick={handlePhoneVerifyClick}
                                />
                              </Grid>
                            ),
                          }}
                          />)
                            }
                        </Grid>

                        <Grid item xs={12} sm={12} md={5.9}>
                        {!otpSent.email ?
                          (<TextField
                            fullWidth
                            size="small"
                            label="Email"
                            name="email"
                            variant="outlined"
                            margin="normal"
                            value={signupFormData.email}
                            onChange={handleSignupChange}
                            error={!!signupErrors.email}
                            required
                            InputProps={{
                              endAdornment: (
                                <Typography
                                  variant="body2"
                                  style={{ cursor: "pointer", textDecoration: 'underline',paddingLeft: '4%' }}
                                  onClick={handleEmailVerifyClick} 
                                >
                                  Verify
                                </Typography>
                              ),
                            }}
                          />) 
                          : 
                          (<TextField
                            fullWidth
                            size="small"
                            label="OTP"
                            name="emailOtp"
                            variant="outlined"
                            margin="normal"
                            value={signupFormData.emailOtp}
                            onChange={handleSignupChange}
                            error={!!signupErrors.emailOtp}
                            InputProps={{
                            endAdornment: (
                              <Grid
                                container
                                justifyContent="flex-end"
                                alignItems="center"
                                wrap="nowrap"
                                style={{ width: 'auto' }}
                              >
                                <Typography
                                  variant="body2"
                                  color="primary"
                                  style={{ cursor: "pointer",  textDecoration: 'underline', paddingLeft: '4%' }}
                                  // onClick={}
                                >
                                  Submit
                                </Typography>
                                <Typography
                                  variant="body2"
                                  style={{ padding: '0 6px' }}
                                >
                                  /
                                </Typography>
                                <EditIcon
                                  size="small"
                                  style={{ cursor: "pointer",fontSize: '1rem', }}
                                  onClick={handleEmailVerifyClick}
                                />
                              </Grid>
                            ),
                          }}
                          />) 
                        }

                        </Grid>


                        <Grid item xs={12} sm={12} md={5.9}>
                          <TextField
                            fullWidth
                            size="small"
                            label="Street Address"
                            name="streetAddress"
                            variant="outlined"
                            margin="normal"
                            value={signupFormData.streetAddress}
                            onChange={handleSignupChange}
                            error={!!signupErrors.streetAddress}
                            required
                          />
                        </Grid>


                        <Grid item xs={12} sm={12} md={5.9}>
                          <TextField
                            fullWidth
                            size="small"
                            label="City"
                            name="city"
                            variant="outlined"
                            margin="normal"
                            value={signupFormData.city}
                            onChange={handleSignupChange}
                            error={!!signupErrors.city}
                            required
                          />
                        </Grid>

                        <Grid item xs={12} sm={12} md={5.9}>
                          <TextField
                            fullWidth
                            size="small"
                            label="State"
                            name="state"
                            variant="outlined"
                            margin="normal"
                            value={signupFormData.state}
                            onChange={handleSignupChange}
                            error={!!signupErrors.state}
                            required
                          />
                        </Grid>

                        <Grid item xs={12} sm={12} md={5.9}>
                          <TextField
                            fullWidth
                            size="small"
                            label="Zip Code"
                            name="zipCode"
                            variant="outlined"
                            margin="normal"
                            value={signupFormData.zipCode}
                            onChange={handleSignupChange}
                            error={!!signupErrors.zipCode}
                            required
                          />
                        </Grid>
                        
                        <Grid item xs={12} sm={12} md={5.9}>
                          <TextField
                            fullWidth
                            size="small"
                            label="Country"
                            name="country"
                            variant="outlined"
                            margin="normal"
                            value={signupFormData.country}
                            onChange={handleSignupChange}
                            error={!!signupErrors.country}
                            disabled
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={5.9}>
                          <TextField
                            fullWidth
                            size="small"
                            label="GST"
                            name="gst"
                            variant="outlined"
                            margin="normal"
                            value={signupFormData.gst}
                            onChange={handleSignupChange}
                            error={!!signupErrors.gst}
                            
                          />
                        </Grid>
                      </Grid>
                      <Button
                        fullWidth
                        size="large"
                        variant="contained"
                        color="primary"
                        sx={{ mt: 3 }}
                        onClick={handleNext}
                        disabled={loading}
                      >
                        {loading ? <CircularProgress size={24} /> : 'Next'}
                      </Button>
                    </>
                  )}

                  {/* Step 2 */}
                  {activeStep === 1 && (
                    <>
                    <Grid container justifyContent="space-between">
{/* 
                      <Grid item xs={12} sm={12} md={8.8}>
                        <TextField
                          disabled
                          fullWidth
                          size="small"
                          label="Email"
                          name="email"
                          variant="outlined"
                          margin="normal"
                          value={signupFormData.email}
                          onChange={handleSignupChange}
                          error={!!signupErrors.email}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={3} sx={{display:'flex', alignItems:'center'}}>
                        {!isVerified.email ? (<Button
                          fullWidth
                          variant="outlined"
                          color="primary"
                          onClick={() => handleSendOtp('email')}
                          size='medium'
                        >
                          {otpSent.email ? "Resend Otp" : 'Send Otp'}
                        </Button> )
                          :
                        (<Button
                          fullWidth
                          variant="outlined"
                          color="success"
                          size='medium'
                        >
                          {'Verified'}
                        </Button>)}
                      </Grid> */}
                      {/* {otpSent.email && (
                        <Grid item xs={12} sm={12} md={12}>
                          <Grid container justifyContent="space-between">
                            <Grid item xs={12} sm={12} md={8.8}>
                              <TextField
                                fullWidth
                                size="small"
                                label="Email OTP"
                                name="emailOtp"
                                variant="outlined"
                                margin="normal"
                                value={otp.emailOtp}
                                onChange={handleOtpChange}
                              />
                            </Grid>
                            <Grid item xs={12} sm={12} md={3} sx={{display:'flex', alignItems:'center'}}>
                              <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                          onClick={() => handleVerifyOtp('email')}
                                size='medium'
                              >
                                Verify Otp
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      )} */}
                      {/* <Grid item xs={12} sm={12} md={8.8}>
                        <TextField
                          fullWidth
                          size="small"
                          label="Phone"
                          name="phone"
                          variant="outlined"
                          margin="normal"
                          value={signupFormData.phone}
                          onChange={handleSignupChange}
                          error={!!signupErrors.phone}
                          disabled
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={3} sx={{display:'flex', alignItems:'center'}}>
                        {!isVerified.phone ? ( <Button
                          fullWidth
                          variant="outlined"
                          color="primary"
                          onClick={() => handleSendOtp('phone')}
                          size='medium'
                        >
                          {otpSent.phone ? "Resend Otp" : 'Send Otp'}
                        </Button>) :
                        (<Button
                          fullWidth
                          variant="outlined"
                          color="success"
                          size='medium'
                        >
                          {'Verified'}
                        </Button>)}
                      </Grid>
                      {otpSent.phone && (
                        <Grid item xs={12} sm={12} md={12}>
                          <Grid container justifyContent="space-between">
                            <Grid item xs={12} sm={12} md={8.8}>
                              <TextField
                                fullWidth
                                size="small"
                                label="Phone OTP"
                                name="phoneOtp"
                                variant="outlined"
                                margin="normal"
                                value={otp.phoneOtp}
                                onChange={handleOtpChange}
                              />
                            </Grid>
                            <Grid item xs={12} sm={12} md={3} sx={{display:'flex', alignItems:'center'}}>
                              <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                          onClick={() => handleVerifyOtp('phone')}
                                size='medium'
                              >
                                Verify Otp
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      )} */}
                    
                      <Grid item xs={12} sm={12} md={12}>
                          <TextField
                            fullWidth
                            size="small"
                            label="Password"
                            name="password"
                            variant="outlined"
                            margin="normal"
                            type="password"
                            value={signupFormData.password}
                            onChange={handleSignupChange}
                            error={!!signupErrors.password}
                          />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12}>
                          <TextField
                            fullWidth
                            size="small"
                            label="Confirm Password"
                            name="confirmPassword"
                            variant="outlined"
                            margin="normal"
                            type="password"
                            value={signupFormData.confirmPassword}
                            onChange={handleSignupChange}
                            error={!!signupErrors.confirmPassword}
                          />
                        </Grid>
                    </Grid>
                      <Grid container spacing={1} >
                        <Grid item xs={4}>
                          <Button
                            fullWidth
                            size="large"
                            variant="outlined"
                            color="primary"
                            sx={{ mt: 3 }}
                            onClick={handleBack}
                          >
                            Back
                          </Button>
                        </Grid>
                        <Grid item xs={8}>
                          <Button
                            fullWidth
                            size="large"
                            variant="contained"
                            color="primary"
                            sx={{ mt: 3 }}
                            onClick={handleSignupSubmit}
                            disabled={loading}
                          >
                            {loading ? <CircularProgress size={24} /> : 'Sign Up'}
                          </Button>
                        </Grid>
                      </Grid>
                    </>
                  )}
                </>
              )}

              {/* Login Form */}
              {isLogin && (
                <>
                  <TextField
                    fullWidth
                    size="small"
                    label="Email"
                    name="email"
                    variant="outlined"
                    margin="normal"
                    value={loginFormData.email}
                    onChange={handleLoginChange}
                    error={!!loginErrors.email}
                  />
                  <TextField
                    fullWidth
                    size="small"
                    label="Password"
                    name="password"
                    type="password"
                    variant="outlined"
                    margin="normal"
                    value={loginFormData.password}
                    onChange={handleLoginChange}
                    error={!!loginErrors.password}
                  />
                  <Button
                    fullWidth
                    size="large"
                    variant="contained"
                    color="primary"
                    sx={{ mt: 3 }}
                    onClick={handleLoginSubmit}
                    disabled={loading}
                  >
                    {loading ? <CircularProgress size={24} /> : 'Login'}
                  </Button>
                </>
              )}
            </Box>
            <Grid sx={{display:{xs:'flex',sm:'flex',md:'none'}}}>
              <Typography variant='body2' sx={{padding:'18px 0', color:'#1976d2'}} fontWeight='bold' onClick={() => setIsLogin(!isLogin)}>
                {isLogin ? 'Create an Account' : 'Already have an account? Login'}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
