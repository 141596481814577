// src/components/Navbar/Navbar.js
import React, { useState, useEffect } from "react";
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Divider,
  Typography,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Grid,
  Menu,
  MenuItem,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Link } from "react-router-dom";
import logo from "../logos/logo4.jpg";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import Auth from "./auth/Auth";
import { toast } from "react-toastify";

const Navbar = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [openAuth, setOpenAuth] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const handleAuthOpen = () => {
    setOpenAuth(true);
    setAnchorEl(null);
  };

  const handleAuthClose = () => {
    setOpenAuth(false);
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.removeItem("appbowlUser");
    setIsLoggedIn(false);
    toast.success("Logout Sucessful");
    setAnchorEl(null);
    // history.push('/'); // Navigate to homepage
  };

  useEffect(() => {
    const savedUser = localStorage.getItem("appbowlUser");
    if (savedUser) {
      const user = JSON.parse(savedUser);
      console.log(user); // Access the stored user object
      setIsLoggedIn(true);
      setAnchorEl(null); // Set user as logged in
    }
  }, [openAuth]);

  const drawerList = (toggleDrawer) => (
    <Box
      sx={{
        width: 250,
        height: "100%",
        bgcolor: "#f5f5f5",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
      role="presentation"
      onClick={toggleDrawer(false)}
    >
      <Box>
        <List sx={{ padding: 0 }}>
          {[
            { text: "Home", link: "/", icon: null },
            { text: "About", link: "/about", icon: null },
            { text: "Contact", link: "/contact", icon: null },
            {
              text: "Cart",
              link: "/user/cart",
              icon: (
                <ShoppingCartIcon sx={{ color: "#666", fontSize: "1.2rem" }} />
              ),
            },
            isLoggedIn
              ? {
                  text: "Logout",
                  link: "#",
                  action: handleLogout,
                  icon: (
                    <LogoutIcon sx={{ color: "#d32f2f", fontSize: "1.2rem" }} />
                  ),
                }
              : {
                  text: "Login",
                  link: "#",
                  action: handleAuthOpen,
                  icon: (
                    <LoginIcon sx={{ color: "#1976d2", fontSize: "1.2rem" }} />
                  ),
                },
          ].map((item, index) => (
            <Box key={index}>
              <ListItem
                button
                component={item.link === "#" ? "div" : Link}
                to={item.link !== "#" ? item.link : undefined}
                onClick={item.action || toggleDrawer(false)}
                sx={{
                  "&:hover": { bgcolor: "#f0f4ff" },
                  transition: "all 0.3s ease",
                  padding: "10px 16px", // Adjust padding for a better layout
                }}
              >
                {item.icon && (
                  <Box sx={{ mr: 1, display: "flex", alignItems: "center" }}>
                    {item.icon}
                  </Box>
                )}
                <ListItemText
                  primaryTypographyProps={{
                    sx: {
                      fontWeight: "bold",
                      fontSize: "1rem",
                      color: "#333",
                      textAlign: "left", // Left-aligns the text
                    },
                  }}
                  primary={item.text}
                />
                <ArrowForwardIosIcon
                  sx={{
                    fontSize: "0.9rem",
                    color: "#666",
                  }}
                />
              </ListItem>
              {index < 5 && <Divider sx={{ bgcolor: "#ddd" }} />}{" "}
              {/* Divider for all except the last item */}
            </Box>
          ))}
        </List>
      </Box>

      <Box
        sx={{
          padding: "16px",
          textAlign: "center",
          bgcolor: "#f0f0f0",
          borderTop: "1px solid #ddd",
        }}
      >
        <Typography variant="body2" color="text.secondary">
          © 2024 AppBowl
        </Typography>
      </Box>
    </Box>
  );

  return (
    <>
      <Auth openAuth={openAuth} handleAuthClose={handleAuthClose} />
      <AppBar
        sx={{
          background: "#fff",
          zIndex: 1500,
          position: "sticky",
          boxShadow: "none",
          borderBottom: "1px solid #62c3da",
          // 0px 2px 12px rgba(0, 0, 0, 0.1)
        }}
      >
        <Toolbar>
          <Typography
            variant="h6"
            sx={{
              flexGrow: 1,
              display: "flex",
            }}
          >
            <img
              src={logo}
              alt="logo"
              style={{
                borderRadius: "5%",
                width: "150px", // Fixed size
                maxWidth: "80%", // Adjusts for smaller screens
              }}
            />
          </Typography>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer(true)}
            sx={{ display: { xs: "flex", sm: "flex", md: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Grid sx={{ display: { xs: "none", sm: "none", md: "flex" } }}>
            <Button component={Link} to="/">
              <Typography variant="body2" fontWeight="bold" color="#000">
                Home
              </Typography>
            </Button>
            <Button
              component={Link}
              to="/marketplace"
              sx={{ margin: "auto 12px" }}
            >
              <Typography variant="body2" fontWeight="bold" color="#000">
                Marketplace
              </Typography>
            </Button>
            <Button component={Link} to="/blogs" sx={{ margin: "auto 12px" }}>
              <Typography variant="body2" fontWeight="bold" color="#000">
                Blogs
              </Typography>
            </Button>
            <Button
              component={Link}
              to="/user/cart"
              sx={{ margin: "auto 12px" }}
            >
              <Typography variant="body2" fontWeight="bold" color="#000">
                Cart
              </Typography>
            </Button>
            <Button component={Link} to="/contact" sx={{ margin: "auto 12px" }}>
              <Typography variant="body2" fontWeight="bold" color="#000">
                Contact
              </Typography>
            </Button>
            {isLoggedIn ? (
              <>
                <AccountCircleIcon
                  sx={{ marginTop: "3.5px", marginLeft: "10px" }}
                  onClick={handleClick}
                />
                <Menu
                  id="user-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "user-button",
                  }}
                >
                  <MenuItem
                    color="#6bd4ed"
                    component={Link}
                    to="/user/dashboard"
                    onClick={handleClose}
                  >
                    Dashboard
                  </MenuItem>
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </Menu>
              </>
            ) : (
              <Button onClick={handleAuthOpen}>
                <Typography variant="body2" fontWeight="bold" color="#000">
                  Login
                </Typography>
              </Button>
            )}
          </Grid>
        </Toolbar>
      </AppBar>
      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
        {drawerList(toggleDrawer)}
      </Drawer>
    </>
  );
};

export default Navbar;
