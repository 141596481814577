// src/pages/Home.js
import React, {useEffect, useState, UseState} from 'react';
import { Grid,Box, Typography, Divider } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useNavigate } from 'react-router-dom';
import Testimonials from '../components/home/testimonials';
import Blogs from '../components/home/blogs';
import Featured from '../components/home/featured';
import bannerL1 from '../bannerL1.jpg'; 


function Home() {

    const navigate = useNavigate();

    const handleNavigation = () => {
      navigate('/marketplace');
    };
    
  return (
    <Grid>
        <Grid container>
            {/* <Grid 
                sx={{
                    backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.7)), url(https://www.shutterstock.com/image-photo/male-hands-typing-on-laptop-600nw-2086966642.jpg)',
                    backgroundSize: 'cover', 
                    backgroundPosition: 'center', 
                    backgroundRepeat: 'no-repeat',
                    width: '100%',
                    minHeight:{xs:'50vh',sm:'70vh',m:'90vh'},
                    display:'flex',
                    alignItems:'center'
                }}
            >
                <Grid sx={{width:{xs:'96%',sm:'96%' , md:'80%'} , margin:'0px auto'}}>
                    <Grid container direction='column'  spacing={2} > 
                        <Grid item >
                            <Typography variant='h4' color='#ffffff'>Welcome to AppBowl: Your Bowl of Software Solutions</Typography>
                        </Grid>
                        <Grid item >
                            <Typography variant='h6' color='#62c3da'>Unlock Value, Unleash Innovation.</Typography>
                        </Grid>
                        <Grid item >
                            <Typography variant='body1' color='#ffffff'>Discover a world of software at unbeatable prices. Join us today and revolutionize your digital experience.</Typography>
                        </Grid>
                        <Grid item pt={2}>
                            <Typography variant='h6' color="#BABABA" sx={{border:'1px solid #BABABA' , borderRadius:'50px' , width:'fit-content' , padding:'2px 20px', fontSize: '18px'}} onClick={handleNavigation} >Explore the Marketplace</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid> */}
            <Grid 
                // sx={{
                //     backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.7)),url(${bannerL1}))`,
                //     backgroundSize: 'cover', 
                //     backgroundPosition: 'center', 
                //     backgroundRepeat: 'no-repeat',
                //     width: '100%',
                //     minHeight: { xs: '60vh', sm: '70vh', md: '90vh' },
                //     display: 'flex',
                //     alignItems: 'center',
                // }}
                sx={{
                    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)), url(${bannerL1})`, 
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    width: '100%',
                    minHeight: { xs: '60vh', sm: '70vh', md: '90vh' },
                    display: 'flex',
                    alignItems: 'center',
                  }}
            >
                <Grid sx={{ width: { xs: '95%', sm: '90%', md: '50%' }, margin: '0 3%' }}>
                    <Grid container direction="column" spacing={2.5}>
                        <Grid item>
                            <Typography 
                                variant='body6'
                                color="#000"
                                sx={{ fontSize: { xs: '1.5rem', sm: '1.5rem', md: '1.2rem' }, fontWeight: '500', textTransform: 'uppercase' }}
                            >
                                Welcome to AppBowl
                            </Typography>
                            <Typography 
                                variant='h3'
                                color="#62c3da"
                                sx={{ fontSize: { xs: '1.5rem', sm: '1.5rem', md: '2rem' }, fontWeight: 'bold', marginTop: '6px', textTransform: 'uppercase', width: '65%' }}
                            >
                                Your Bowl of Software Solutions
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography 
                                variant='h6' 
                                color="#62c3da" 
                                sx={{ fontSize: { xs: '1rem', sm: '1rem', md: '1.3rem' },fontWeight: 'bold' }}
                            >
                                Unlock Value, Unleash Innovation.
                            </Typography>
                            <Typography 
                                variant="h6" 
                                color="#000" 
                                width={'75%'}
                            >
                                Discover a world of software at unbeatable prices. Join us today and revolutionize your digital experience.
                            </Typography>
                        </Grid>
                        {/* <Grid item>
                            <Typography 
                                variant="body1" 
                                color="#000" 
                                width={'60%'}
                            >
                                Discover a world of software at unbeatable prices. Join us today and revolutionize your digital experience.
                            </Typography>
                        </Grid> */}
                        <Grid item pt={2}>
                            <Typography 
                                variant="h6" 
                                sx={{ 
                                    color:'#62c3da',
                                    border: '2px solid #62c3da', 
                                    borderRadius: '50px', 
                                    width: 'fit-content', 
                                    padding: { xs: '2px 15px', sm: '2px 20px' }, // Responsive padding
                                    fontSize: { xs: '16px', sm: '18px' }, // Responsive font size
                                    fontWeight: 'bold',
                                    cursor: 'pointer', // Change cursor to pointer
                                }} 
                                onClick={handleNavigation}
                            >
                                Explore the Marketplace
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        {/* <Grid contsiner justifyContent='space-between'  textAlign='center' direction='column' sx={{width:{xs:'98%', sm:'98%' , md:'80%'},margin:'30px auto'}}>
            <Grid item p={2}>
                <Typography variant='h6' fontWeight='bold'>Discover Our Featured Products</Typography>
            </Grid>
            <Grid item p={2}>
                <Typography variant='subtitle2'>
                    Explore our handpicked selection of top-rated software solutions. At AppBowl, we bring you the latest and most sought-after software products at prices that fit your budget.
                    Whether you're professional, a business owner, or a tech enthusiast, we have something for everyone. Take a look at our featured products below and find the perfect solution for your needs.
                </Typography>
            </Grid>
            <Grid item p={2}>
                <Carousel {...carouselSettings} sx={{justifyContent:'center' , alignItems:'center' , textAlign:'center'}}>
                    {data.length > 0 && data.map((item, index) => (
                         <Grid key={index} container direction='column' sx={{maxWidth:'220px' , margin:'30px auto',padding:'0px 10px' , boxShadow:' rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;'}}>
                             <Grid item p={4}>
                                 <img src={item.imgSrc} alt='office' width='100%' />
                             </Grid>
                             <Grid item>
                                 <Typography variant='subtitle2' >{item.description}</Typography>
                             </Grid>
                             <Grid item p={1} sx={{display:'flex' , justifyContent:'center' , alignItems:'center'}}>
                                 <Typography variant='subtitle1' fontWeight='bold' >Explore  </Typography>
                                 <KeyboardArrowRightIcon />
                             </Grid>
                         </Grid>
                    ))}
                </Carousel>
            </Grid>
            <Grid item p={3} sx={{display:'flex' , justifyContent:'center'}}>
                <Typography variant='body1' sx={{border:'3px solid black' , borderRadius:'50px' , width:'fit-content' , padding:'10px'}} >Explore All Products</Typography>
            </Grid>
        </Grid> */}
        <Featured/>
        {/* <Grid backgroundColor="#000000">
            <Grid container sx={{width:{xs:'98%',sm:'98%',md:'80%'},margin:'20px auto'}}>
                <Grid item xs={12} md={6}>
                    <Grid container direction="column" sx={{padding: "20px", color: "#fff"}}>
                         <Typography variant='h6'  color="#62c3da" marginBottom={'2vh'}>Why Choose AppBowl?</Typography>
                        <Grid item md={12} pb={1}>
                            <Typography variant='body2' color="#BABABA" p={0.5}>Quality You Can Trust</Typography>
                            <Divider sx={{ borderBottomWidth: '2px', borderColor: '#BABABA' }} />
                        </Grid>
                        <Grid item md={12} pb={1}>
                            <Typography variant='body2' color="#BABABA" p={0.5}>Cutting-Edge Technology</Typography>
                            <Divider sx={{ borderBottomWidth: '2px', borderColor: '#BABABA' }} />
                        </Grid>
                        <Grid item md={12} pb={1}>
                            <Typography variant='body2' color="#BABABA" p={0.5}>Tailored for Every Need</Typography>
                            <Divider sx={{ borderBottomWidth: '2px', borderColor: '#BABABA' }} />
                        </Grid>
                        <Grid item md={12} pb={1}>
                            <Typography variant='body2' color="#BABABA" p={0.5}>Affordable Software Solutions</Typography>
                            <Divider sx={{ borderBottomWidth: '2px', borderColor: '#BABABA' }} />
                        </Grid>
                        <Grid item md={12} pb={1}>
                            <Typography variant='body2' color="#BABABA" p={0.5}>Exclusive Deals & Discounts</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                    <img style={{}}  src='https://t3.ftcdn.net/jpg/04/16/75/96/360_F_416759692_ZZh8BcW3uo3ejFNVyOERVDZuLvDAlHfB.jpg' alt='small_banner'/>
                </Grid>
            </Grid>
        </Grid> */}
        <Grid backgroundColor="#000000">
            <Grid
                container
                sx={{
                width: { xs: '98%', sm: '98%', md: '80%' },
                margin: '20px auto',
                padding: '30px 0'
                }}
            >
                {/* Left Content */}
                <Grid item xs={12} md={6} sx={{display:'flex',alignItems:'center'}}>
                <Grid
                    container
                    direction="column"
                    sx={{
                    padding: { xs: '10px', md: '20px' },
                    color: '#fff',
                    }}
                >
                    <Typography
                    variant="h6"
                    color="#62c3da"
                    marginBottom="2vh"
                    textAlign={{ xs: 'center', md: 'left' }}
                    >
                    Why Choose AppBowl?
                    </Typography>
                    {[
                    'Quality You Can Trust',
                    'Cutting-Edge Technology',
                    'Tailored for Every Need',
                    'Affordable Software Solutions',
                    'Exclusive Deals & Discounts',
                    ].map((text, index) => (
                    <Grid item xs={12} key={index} pb={1}>
                        <Typography
                        variant="body2"
                        color="#fff"
                        p={0.5}
                        textAlign={{ xs: 'center', md: 'left' }}
                        >
                        {text}
                        </Typography>
                        {index !== 5 && (
                        <Divider
                            sx={{
                            borderBottomWidth: '2px',
                            borderColor: '#BABABA',
                            marginX: { xs: 'auto', md: '0' },
                            }}
                        />
                        )}
                    </Grid>
                    ))}
                </Grid>
                </Grid>

                {/* Right Image */}
                <Grid
                item
                xs={12}
                md={6}
                display="flex"
                justifyContent="center"
                alignItems="center"
                >
                <img
                    src="https://t3.ftcdn.net/jpg/04/16/75/96/360_F_416759692_ZZh8BcW3uo3ejFNVyOERVDZuLvDAlHfB.jpg"
                    alt="small_banner"
                    style={{
                    maxWidth: '80%',
                    height: 'auto',
                    borderRadius: '10px',
                    }}
                />
                </Grid>
            </Grid>
            </Grid>
        {/* <Grid backgroundColor="#000000" >
            <Grid container sx={{width:'80%', margin:'0px auto' , padding:'50px 0'}}>
                <Grid item xs={12} sm={12} md={6} sx={{display:'flex' , alignItems:'center'}} >
                    <Grid container sx={{width:{xs:'100%',sm:'100%',md:'50%'} , margin:'0px auto'}}>
                        <Typography variant='h6'  color="#62c3da" marginBottom={'2vh'}>Why Choose AppBowl?</Typography>
                        <Grid item md={12} pb={1}>
                            <Typography variant='body2' color="#BABABA" p={0.5}>Quality You Can Trust</Typography>
                            <Divider sx={{ borderBottomWidth: '2px', borderColor: '#BABABA' }} />
                        </Grid>
                        <Grid item md={12} pb={1}>
                            <Typography variant='body2' color="#BABABA" p={0.5}>Cutting-Edge Technology</Typography>
                            <Divider sx={{ borderBottomWidth: '2px', borderColor: '#BABABA' }} />
                        </Grid>
                        <Grid item md={12} pb={1}>
                            <Typography variant='body2' color="#BABABA" p={0.5}>Tailored for Every Need</Typography>
                            <Divider sx={{ borderBottomWidth: '2px', borderColor: '#BABABA' }} />
                        </Grid>
                        <Grid item md={12} pb={1}>
                            <Typography variant='body2' color="#BABABA" p={0.5}>Affordable Software Solutions</Typography>
                            <Divider sx={{ borderBottomWidth: '2px', borderColor: '#BABABA' }} />
                        </Grid>
                        <Grid item md={12} pb={1}>
                            <Typography variant='body2' color="#BABABA" p={0.5}>Exclusive Deals & Discounts</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={6} >
                    <Grid  container sx={{width:{xs:'100%',sm:'100%',md:'50%'} , margin:'0px auto'}}>
                        <img className='img_cont'  src='https://t3.ftcdn.net/jpg/04/16/75/96/360_F_416759692_ZZh8BcW3uo3ejFNVyOERVDZuLvDAlHfB.jpg' alt='small_banner'/>
                    </Grid>
                </Grid>
            </Grid>
        </Grid> */}
        <Testimonials />
        <Grid 
            sx={{
                backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.7)), url(https://as2.ftcdn.net/v2/jpg/05/05/94/37/1000_F_505943738_m2Ro0rZQ1casOwxMBDxHwWEUwUTmUwka.jpg)',
                backgroundSize: 'cover', 
                backgroundPosition: 'center', 
                backgroundRepeat: 'no-repeat',
                width: '100%',
            }}
        >
            <Grid container justifyContent='center' alignItems='center' textAlign='center' direction='column' p={2}>
                <Grid item p={2}>
                    <Typography variant='h6'  color="#62c3da">Early Birds, Big Rewards</Typography>
                </Grid>
                <Grid item>
                    <Typography variant='subtitle1' color="#BABABA">
                            Seize the opportunity to get exclusive deals and special offers by being an early adopter. Enjoy significant rewards and discounts on our top-rated software products before anyone else!
                    </Typography>
                </Grid>
                <Grid item p={2}>
                    <Typography variant='body1' color="#BABABA" sx={{border:'2px solid #BABABA' , borderRadius:'50px' , width:'fit-content' , padding:'2px 10px'}} >Explore</Typography>
                </Grid>
            </Grid>

        </Grid>
        <Blogs/>
        <Grid 
            sx={{
                backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.7)), url(https://as2.ftcdn.net/v2/jpg/05/05/94/37/1000_F_505943738_m2Ro0rZQ1casOwxMBDxHwWEUwUTmUwka.jpg)',
                backgroundSize: 'cover', 
                backgroundPosition: 'center', 
                backgroundRepeat: 'no-repeat',
                width: '100%',
            }}
        >
            <Grid container justifyContent='center' alignItems='center' textAlign='center' direction='column' p={2}>
                <Grid item p={2}>
                    <Typography variant='h6'  color="#62c3da">Early Birds, Big Rewards</Typography>
                </Grid>
                <Grid item>
                    <Typography variant='subtitle2' color="#BABABA">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt u id est laborum.
                    </Typography>
                </Grid>
            </Grid>
            <Grid container sx={{width:{xs:'98%',sm:'98%',md:'80%'},margin:'20px auto',justifyContent:{xs:'center',sm:'center', md:'space-between'},alignItems:'center',textAlign:'center'}} pt={5} pb={5}>
                <Grid item xs={8} sm={4} md={2.2} sx={{margin:'10px auto'}}>
                    <Grid container direction='column' sx={{border:'3px solid #ffffff',borderRadius:'15%',padding:'20px 0'}}>
                        <Grid item md={!2}>
                            <Typography variant='h3' color='#ffffff'>1</Typography>
                            <Grid container sx={{width:'50%' ,margin:'0px auto'}}>
                                <Divider sx={{borderColor:'#73642c' ,borderWidth:'1.2px',width:'100%'}} />
                            </Grid>
                        </Grid>
                        <Grid item md={!2}>
                            <Typography variant='h6' color='#ffffff' fontWeight='bold'>Search</Typography>
                        </Grid>
                        <Grid item md={!2}>
                            <Typography variant="body2" color='#ffffff'>Begin by searching for the perfect software solution that fits your needs. Use filters and categories to narrow down your options quickly.</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={8} sm={4} md={2.2} sx={{margin:'10px auto'}}>
                    <Grid container direction='column' sx={{border:'3px solid #ffffff',borderRadius:'15%',padding:'20px 0'}}>
                        <Grid item md={!2}>
                            <Typography variant='h3' color='#ffffff'>2</Typography>
                            <Grid container sx={{width:'50%' ,margin:'0px auto'}}>
                                <Divider sx={{borderColor:'#73642c' ,borderWidth:'1.2px',width:'100%'}} />
                            </Grid>
                        </Grid>
                        <Grid item md={!2}>
                            <Typography variant='h6' color='#ffffff' fontWeight='bold'>Explore</Typography>
                        </Grid>
                        <Grid item md={!2}>
                            <Typography variant="body2" color='#ffffff'>Browse through detailed descriptions and features to help you make an informed decision about the software you want to purchase.</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={8} sm={4} md={2.2} sx={{margin:'10px auto'}}>
                    <Grid container direction='column' sx={{border:'3px solid #ffffff',borderRadius:'15%',padding:'20px 0'}}>
                        <Grid item md={!2}>
                            <Typography variant='h3' color='#ffffff'>3</Typography>
                            <Grid container sx={{width:'50%' ,margin:'0px auto'}}>
                                <Divider sx={{borderColor:'#73642c' ,borderWidth:'1.2px',width:'100%'}} />
                            </Grid>
                        </Grid>
                        <Grid item md={!2}>
                            <Typography variant='h6' color='#ffffff' fontWeight='bold'>Select</Typography>
                        </Grid>
                        <Grid item md={!2}>
                            <Typography variant="body2" color='#ffffff'>Choose the software that best meets your requirements and fits your budget, ensuring it's the right choice for you.</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={8} sm={4} md={2.2} sx={{margin:'10px auto'}}>
                    <Grid container direction='column' sx={{border:'3px solid #ffffff',borderRadius:'15%',padding:'20px 0'}}>
                        <Grid item md={!2}>
                            <Typography variant='h3' color='#ffffff'>4</Typography>
                            <Grid container sx={{width:'50%' ,margin:'0px auto'}}>
                                <Divider sx={{borderColor:'#73642c' ,borderWidth:'1.2px',width:'100%'}} />
                            </Grid>
                        </Grid>
                        <Grid item md={!2}>
                            <Typography variant='h6' color='#ffffff' fontWeight='bold'>Add to Cart</Typography>
                        </Grid>
                        <Grid item md={!2}>
                            <Typography variant="body2" color='#ffffff'>Once you've found your ideal software, simply add it to your cart and review your selection before proceeding.</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={8} sm={4} md={2.2} sx={{margin:'10px auto'}}>
                    <Grid container direction='column' sx={{border:'3px solid #ffffff',borderRadius:'15%',padding:'20px 0'}}>
                        <Grid item md={!2}>
                            <Typography variant='h3' color='#ffffff'>5</Typography>
                            <Grid container sx={{width:'50%' ,margin:'0px auto'}}>
                                <Divider sx={{borderColor:'#73642c' ,borderWidth:'1.2px',width:'100%'}} />
                            </Grid>
                        </Grid>
                        <Grid item md={!2}>
                            <Typography variant='h6' color='#ffffff' fontWeight='bold'>Checkout</Typography>
                        </Grid>
                        <Grid item md={!2}>
                            <Typography variant="body2" color='#ffffff'>Complete your purchase securely with a seamless checkout process. Enjoy instant access to your new software and start using it right away!</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

        </Grid>
    </Grid>
  );
}

export default Home;
