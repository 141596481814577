import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import Home1 from './pages/Home1';
import Marketplace from './pages/Marketplace';
import MarketplacePage from './components/Product/Product';
import Cart from './components/cart/cart';
import OrderSucess from './pages/Ordersuccess';
import OrderFailed from './pages/OrderFailed';
import UserPortal from './pages/UserDashboard';
import Blogs from './pages/Blogs';
import BlogDetail from './components/blogDetails';
import ContactUs from './pages/Contactus';
import NotFoundPage from './components/PageNotFound';
import './App.css';

// Wrapper to conditionally render Navbar and Footer
const Layout = ({ children }) => {
  const location = useLocation();
  const hideNavbarFooter = location.pathname === '/'; // Hide for `/` route

  return (
    <>
      {!hideNavbarFooter && <Navbar />}
      <ToastContainer />
      {children}
      {!hideNavbarFooter && <Footer />}
    </>
  );
};

function App() {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route exact path="/" element={<Home1 />} />
          <Route exact path="/home" element={<Home />} />
          <Route exact path="/marketplace" element={<Marketplace />} />
          <Route exact path="/marketplace/product/:id" element={<MarketplacePage />} />
          <Route exact path="/user/cart" element={<Cart />} />
          <Route exact path="/order/success" element={<OrderSucess />} />
          <Route exact path="/order/failed" element={<OrderFailed />} />
          <Route exact path="/user/dashboard" element={<UserPortal />} />
          <Route exact path="/blogs" element={<Blogs />} />
          <Route exact path="/contact" element={<ContactUs />} />
          <Route exact path="/blog/:id" element={<BlogDetail />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;
