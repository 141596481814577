import React, {useState, useEffect} from "react";
import { Grid, Typography } from "@mui/material";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { getBlogList } from "../../apiCalls";

const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const carouselSettings = {
    responsive,
    autoPlay: true,
    autoPlaySpeed: 7000,
    infinite: true,
     
  };
  const slickSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <CustomRightArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  

  const data = [
    {
      id: 1,
      imgSrc: 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/49/Office_365_logo_%282013-2019%29.png/800px-Office_365_logo_%282013-2019%29.png',
      description: 'Boost your productivity with Microsoft Office 365. Access Word, Excel, Powerpoint and more from anywhere, on any device.',
    },
    {
      id: 2,
      imgSrc: 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/49/Office_365_logo_%282013-2019%29.png/800px-Office_365_logo_%282013-2019%29.png',
      description: 'Boost your productivity with Microsoft Office 365. Access Word, Excel, Powerpoint and more from anywhere, on any device.',
    },
    {
      id: 3,
      imgSrc: 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/49/Office_365_logo_%282013-2019%29.png/800px-Office_365_logo_%282013-2019%29.png',
      description: 'Boost your productivity with Microsoft Office 365. Access Word, Excel, Powerpoint and more from anywhere, on any device.',
    },
    {
      id: 4,
      imgSrc: 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/49/Office_365_logo_%282013-2019%29.png/800px-Office_365_logo_%282013-2019%29.png',
      description: 'Boost your productivity with Microsoft Office 365. Access Word, Excel, Powerpoint and more from anywhere, on any device.',
    },
    {
      id: 5,
      imgSrc: 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/49/Office_365_logo_%282013-2019%29.png/800px-Office_365_logo_%282013-2019%29.png',
      description: 'Boost your productivity with Microsoft Office 365. Access Word, Excel, Powerpoint and more from anywhere, on any device.',
    },   
    {
      id: 6,
      imgSrc: 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/49/Office_365_logo_%282013-2019%29.png/800px-Office_365_logo_%282013-2019%29.png',
      description: 'Boost your productivity with Microsoft Office 365. Access Word, Excel, Powerpoint and more from anywhere, on any device.',
    },
    // Add more items as needed
  ];


  const Blogs = () => {


  const [blogs, setBlogs] = useState([]); 
  const [error, setError] = useState(''); 

  const fetchTestimonialsWithHandling = async () => {
    try {
      const data = await getBlogList();
      setBlogs(data.data);
    } catch (err) {
      setError('Failed to load testimonials. Please try again later.');
      console.error('Error fetching testimonials:', err);
    }
  };

  useEffect(() => {
    fetchTestimonialsWithHandling();
  }, []);


  const truncateText = (text, maxLength = 120) => {
    if (!text) return "-";
    return text.length > maxLength ? text.substring(0, maxLength) + "..." : text;
  };
  
  const handleBlogDetail = () => {
    window.location.href = '/blog/23';
  }

  const CustomRightArrow = ({ onClick }) => {
    return (
      <button
        onClick={onClick}
        style={{
          position: "absolute",
          right: "10px",
          bottom: "-5%",
          transform: "translateY(-50%)",
          zIndex: 1000,
          backgroundColor: "#6200ed",
          border: "none",
          borderRadius: "50%",
          padding: "10px",
          cursor: "pointer",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
        }}
      >
        
      </button>
    );
  };
  

    return(
    <Grid sx={{background:'#f7f7f7'}}>
        <Grid contsiner justifyContent='space-between'  textAlign='center' pt={2} direction='column' sx={{width:{xs:'98%', sm:'98%' , md:'80%'},margin:'0px auto'}}>
            <Grid item p={2}>
                <Typography variant='h6' fontWeight='bold'>Stay Informed with Appbowl's Blog</Typography>
            </Grid>
            <Grid item p={2}>
                <Typography variant='subtitle2'>
                    Explore our handpicked selection of top-rated software solutions. At AppBowl, we bring you the latest and most sought-after software products at prices that fit your budget.
                    Whether you're professional, a business owner, or a tech enthusiast, we have something for everyone. Take a look at our featured products below and find the perfect solution for your needs.
                </Typography>
            </Grid>
            <Grid item p={2}>
            {/*   */}
            <Slider {...slickSettings} style={{ textAlign: 'center' }}>
    {blogs?.length > 0 &&
      blogs.map((item, index) => (
        <Grid
          key={index}
          sx={{
            maxWidth: '260px',
            marginBottom: '10px',
            margin: '30px auto',
            boxShadow:
              'rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
            borderRadius: '4%',
            overflow: 'hidden',
          }}
        >
          <Grid container justifyContent="space-between" direction="column">
            <Grid item md={12}>
              <img
                src={
                  item.image ||
                  "https://media.istockphoto.com/id/1947499362/photo/happy-group-of-business-people-discussing-strategy-during-team-meeting-at-the-office-desk.webp?b=1&s=170667a&w=0&k=20&c=AQAPKF3qp3LWmm3F_aQ_TOEghFTvbIVwzuG6etVf18I="
                }
                alt="blog"
                style={{
                  width: '100%',
                  minHeight: '160px',
                  maxHeight: '160px',
                }}
              />
            </Grid>
            <Grid item md={12}>
              <Grid container direction="column" spacing={0.8} p={0.9}>
                <Grid
                  item
                  md={12}
                  sx={{ minHeight: '60px', maxHeight: '60px' }}
                >
                  <Typography
                    variant="body2"
                    textAlign="left"
                    fontWeight="bold"
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      wordBreak: 'break-word',
                    }}
                  >
                    {item.title || '-'}
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={12}
                  sx={{ minHeight: '85px', maxHeight: '85px' }}
                >
                  <Typography variant="body2" textAlign="left">
                    {truncateText(item.content)}
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={12}
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                  onClick={handleBlogDetail}
                >
                  <Typography variant="body2" fontWeight="bold">
                    Explore
                  </Typography>
                  <KeyboardArrowRightIcon />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ))}
  </Slider>
            </Grid>
            <Grid item sx={{display:'flex' , justifyContent:'center', paddingBottom: '10vh'}}>
                <Typography variant='body1' sx={{border:'1px solid black' , borderRadius:'50px' , width:'fit-content', padding:'10px 20px', fontSize: '14px', fontWeight:'bold', cursor:'pointer'}} onClick={()=>{window.location.href = "/blogs"}} >Explore All Blog Posts</Typography>
            </Grid>
        </Grid>
    </Grid>
    )
}

export default Blogs;