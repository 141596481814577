import React, {useState, useEffect} from "react";
import { Grid, Typography } from "@mui/material";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { getFeaturedProductsList } from "../../apiCalls";
import { useNavigate } from 'react-router-dom';


const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const carouselSettings = {
    responsive,
    autoPlay: true,
    autoPlaySpeed: 7000,
    infinite: true,
  };

  const Featured = () => {


    const navigate = useNavigate();

    const handleNavigation = () => {
      navigate('/marketplace');
    };
  

  const [products, setProducts] = useState([]); 
  const [error, setError] = useState(''); 

  const fetchTestimonialsWithHandling = async () => {
    try {
      const data = await getFeaturedProductsList();
      if(data.success){
        setProducts(data.data);
      }
    } catch (err) {
      setError('Failed to load testimonials. Please try again later.');
      console.error('Error fetching testimonials:', err);
    }
  };

  useEffect(() => {
    fetchTestimonialsWithHandling();
  }, []);


  const truncateText = (text, maxLength = 140) => {
    return text.length > maxLength ? text.substring(0, maxLength) + "..." : text;
  }

  const handleProduvtView = (id) => {
    window.location.href = `/marketplace/product/${id}`
  }

    return(
        <Grid contsiner justifyContent='space-between'  textAlign='center' direction='column' sx={{width:{xs:'98%', sm:'98%' , md:'80%'},margin:'30px auto'}}>
            <Grid item p={2}>
                <Typography variant='h6' fontWeight='bold'>Discover Our Featured Products</Typography>
            </Grid>
            <Grid item p={2}>
                <Typography variant='subtitle2'>
                    Explore our handpicked selection of top-rated software solutions. At AppBowl, we bring you the latest and most sought-after software products at prices that fit your budget.
                    Whether you're professional, a business owner, or a tech enthusiast, we have something for everyone. Take a look at our featured products below and find the perfect solution for your needs.
                </Typography>
            </Grid>
            <Grid item p={2}>
                <Carousel {...carouselSettings} sx={{justifyContent:'center' , alignItems:'center' , textAlign:'center'}}>
                    {products?.length > 0 && products.map((item, index) => (
                        <Grid key={index} container direction='column' sx={{maxWidth:'240px' , margin:'30px auto',padding:'0px 10px' , boxShadow:' rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;'}}>
                            <Grid item p={4}>
                                <img src={item.marketing_defaultImage_content} alt='office' width='100%' />
                            </Grid>
                            <Grid item>
                                <Typography variant='subtitle2' sx={{minHeight:'120px',maxHeight:'120px'}} > {truncateText(item.marketing_description)}</Typography>
                            </Grid>
                            <Grid item p={1} sx={{display:'flex' , justifyContent:'center' , alignItems:'center', cursor:'pointer'}} onClick={()=>{handleProduvtView(item.product_name)}}>
                                <Typography variant='subtitle1' fontWeight='bold' >Explore</Typography>
                                <KeyboardArrowRightIcon />
                            </Grid>
                        </Grid>
                    ))}
                </Carousel>
            </Grid>
            <Grid item sx={{display:'flex' , justifyContent:'center',paddingBottom: '10vh'}} >
                <Typography variant='body1' sx={{border:'1px solid black' , borderRadius:'50px' , width:'fit-content', padding:'10px 20px', fontSize: '14px', cursor:'pointer'}} onClick={handleNavigation}>Explore All Products</Typography>
            </Grid>
        </Grid>
    )
}


export default Featured;